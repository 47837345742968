import { H } from "@jfrk/react-heading-levels";
import { PageGrid, PageGridItem } from "@whitespace/components";
import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import TextContent from "../@whitespace/gatsby-theme-wordpress-basic/components/TextContent";

import * as defaultStyles from "./TermHeader.module.css";

AuthorTermHeader.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function AuthorTermHeader({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const {
    term: {
      name,
      description,
      content,
      socialMediaLinks: { links: socialMediaLinks },
      contentMedia,
    },
  } = usePageContext();
  const { processContent } = useHTMLProcessor();

  return (
    <header className={clsx(styles.component, className)} {...restProps}>
      <PageGrid>
        <PageGridItem className={layout.componentWrapperPadding}>
          <div className={styles.wrapper}>
            <H className={styles.title}>{name}</H>
            {description && <p className={styles.preamble}>{description}</p>}
            {content && (
              <TextContent className={styles.content}>
                {processContent(content)}
              </TextContent>
            )}
          </div>
        </PageGridItem>
      </PageGrid>
    </header>
  );
}
