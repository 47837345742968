import { Section } from "@jfrk/react-heading-levels";
import { ModularityArea } from "@municipio/gatsby-theme-basic/src/components";
import { PageGrid, PageGridItem } from "@whitespace/components";
import { Image } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import AuthorTermHeader from "gatsby-theme-vansterpartiet/src/components/AuthorTermHeader";
import SEO from "gatsby-theme-vansterpartiet/src/components/SEO";
import DefaultTermHeader from "gatsby-theme-vansterpartiet/src/components/TermHeader";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./TermTemplate.module.css";

TermTemplate.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  pageContext: PropTypes.shape({
    term: PropTypes.shape({
      name: PropTypes.string,
      content: PropTypes.string,
      contentArea: PropTypes.any,
      contentMedia: PropTypes.arrayOf(PropTypes.object),
      featuredImage: PropTypes.shape({ node: PropTypes.any }),
      taxonomyName: PropTypes.string,
    }),
  }),
};

export default function TermTemplate({ pageContext, styles = defaultStyles }) {
  const {
    term: { name, contentArea, featuredImage, taxonomyName },
  } = pageContext;

  const TermHeader =
    taxonomyName == "author" ? AuthorTermHeader : DefaultTermHeader;
  return (
    <>
      <SEO title={name} isFrontPage={false} />
      <TermHeader />
      <PageGrid>
        <PageGridItem>
          <div className={styles.component}>
            <article>
              <Section>
                {!!featuredImage?.node && <Image {...featuredImage.node} />}
              </Section>
              <ModularityArea area={contentArea} />
            </article>
          </div>
        </PageGridItem>
      </PageGrid>
    </>
  );
}
