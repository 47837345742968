import { H } from "@jfrk/react-heading-levels";
import { PageGrid, PageGridItem } from "@whitespace/components";
import { layout } from "@whitespace/gatsby-theme-wordpress-basic/src/foundation";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./TermHeader.module.css";

TermHeader.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function TermHeader({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  const {
    term: { name, description },
  } = usePageContext();

  return (
    <header className={clsx(styles.component, className)} {...restProps}>
      <PageGrid>
        <PageGridItem className={layout.componentWrapperPadding}>
          <div className={styles.wrapper}>
            <H className={styles.title}>{name}</H>
            {description && <p className={styles.preamble}>{description}</p>}
          </div>
        </PageGridItem>
      </PageGrid>
    </header>
  );
}
